import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

class PianoOffertaFormativaService extends baseRestService {
    constructor() {
        super();
        this.baseUrl = () => settings.baseUrl;
    }

    async Count(params?: pianoOffertaFormativa.queryParams): Promise<number> {
        return await this.Get('/api/pof/count', params);
    }

    async List(params?: pianoOffertaFormativa.queryParams): Promise<pianoOffertaFormativa.item[]> {
        return await this.Get(`/api/pof`, params);
    }

    async Detail(id: string): Promise<pianoOffertaFormativa.item> {
        return await this.Get(`/api/pof/${id}`);
    }

    async GetByDate(data: Date): Promise<pianoOffertaFormativa.item> {
        return await this.Post('/api/pof/getByDate', data);
    }

    async Exist(item: pianoOffertaFormativa.exist) {
        return await this.Post('/api/pof/exists', item);
    }

    async Set(item: pianoOffertaFormativa.item): Promise<pianoOffertaFormativa.item> {
        return await this.Post('/api/pof', item);
    }

    async Remove(id: string) {
        return await this.Delete(`/api/pof/${id}`);
    }
}

export const pofService = new PianoOffertaFormativaService();