import { Vue, Component, Prop } from 'vue-property-decorator';
import { periodoFormativoService } from '../services/periodoFormativoService';
import { ElForm } from 'element-ui/types/form';

@Component({})
export default class ProrogaPeriodoFormativo extends Vue {
  @Prop()
  periodoFormativo: periodoFormativo.item;

  manage: periodoFormativo.item = null;
  created() {
    if (this.periodoFormativo) {
      this.manage = Object.assign({}, this.periodoFormativo);
    }
  }

  get formRules() {
    return {
      dataFineProroga: [{ validator: this.validateDataFineProroga }]
    };
  }

  validateDataFineProroga(rule, value: Date, callback) {
    if (!value || value.toString() === '') {
      callback(new Error("Il campo 'Data Fine Proroga' è obbligatorio"))
    } else if (value <= new Date(this.manage.dataFine, 12, 31, 23, 59, 59)) {
      callback(new Error("La 'Data Fine Proroga' deve essere superiore al valore 'Data Fine' del Periodo Formativo"))
    } else {
      callback();
    }
  }

  get dataFineProrogaPickerOptions() {
    const _self = this;
    return {
      disabledDate(time: Date) {
        if (!_self.manage.dataFine) return false;
        return time.getFullYear() <= _self.manage.dataFine;
      }
    };
  }

  saving: boolean = false;
  async add() {
    (this.$refs.form as ElForm)?.validate(async valid => {
      if (valid) {
        this.saving = true;
        periodoFormativoService.Set(this.manage).then((periodo) => {
          this.$message({
            message: `Il periodo formativo ${this.periodoFormativo.dataInizio} -  ${this.periodoFormativo.dataFine} è stato prorogato correttamente`,
            type: 'success'
          });
          this.saving = false;
          this.$emit('change', periodo.id);
          this.$emit('close');
        }).catch((err) => {
          this.$message({
            message: `Errore durante la proroga del periodo formativo ${this.periodoFormativo.dataInizio} -  ${this.periodoFormativo.dataFine}`,
            type: 'error'
          })
        })
      }
    })
  }
}
