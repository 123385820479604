import { Vue, Component, Watch } from 'vue-property-decorator';
import { debounce } from '@/services/_base/debounce';
import { periodoFormativoService } from '../services/periodoFormativoService';
import { creditiService } from '../services/creditiService';
import ManagePeriodoFormativo from '../components/managePeriodoFormativo.vue';
import ChangeStatePeriodoFormativo from '../components/changeStatePeriodoFormativo.vue';
import ProrogaPeriodoFormativo from '../components/prorogaPeriodoFormativo.vue';

@Component({
  components: {
    ManagePeriodoFormativo,
    ChangeStatePeriodoFormativo,
    ProrogaPeriodoFormativo
  }
})
export default class PeriodoFormativo extends Vue {
  query: periodoFormativo.queryParams = {
    page: 1,
    take: 20,
    stato: null,
    filter: null
  };

  pagination: { pageSizes: number[]; total: number } = {
    pageSizes: [20, 40, 60],
    total: 0
  };

  list: periodoFormativo.item[] = [];

  loading: boolean = false;
  showManage: boolean = false;


  async created() {
    this.loading = true;
    await this.fetchData();
  }

  @Watch('query.filter')
  filterChanged(n, o) {
    if (n !== o) this.fetchDataDebounced(this);
  }

  paginationSizeChange(size) {
    this.query.take = size;
    this.query.page = 1;
    this.fetchData();
  }

  paginationPageChange(page) {
    this.query.page = page;
    this.fetchData();
  }

  displayPofs(row: periodoFormativo.item) {
    this.$router.push({ name: "pof", params: { periodoFormativoId: row.id } });
  }


  manage: periodoFormativo.item = null;

  showChangeState: boolean = false;
  handleChangeState(row: periodoFormativo.item) {
    this.manage = Object.assign({}, row);
    this.showChangeState = true;
  }

  showRequestProroga: boolean = false;
  handleRequestProroga(row: periodoFormativo.item) {
    this.manage = Object.assign({}, row);
    this.showRequestProroga = true;
  }

  async onCancel() {
    this.showManage = false;
    this.showChangeState = false;
    this.showRequestProroga = false;
    this.manage = null;
  }

  async handleRemove(row: periodoFormativo.item) {
    this.$msgbox({
      title: `Attenzione`,
      message: `Il periodo formativo <strong>${row.nome}</strong> verrà cancellato definitivamente. Continuare?`,
      showCancelButton: true,
      confirmButtonText: `Si`,
      cancelButtonText: `Annulla`,
      dangerouslyUseHTMLString: true,
      type: 'warning'
    })
      .then(async () => {
        try {
          await periodoFormativoService.Remove(row.id);
          this.fetchData();
          this.$message({
            message: `Eliminazione del periodo formativo <strong>${row.nome}</strong> effettuato con successo.`,
            dangerouslyUseHTMLString: true,
            duration: 5 * 1000,
            center: true,
            showClose: true,
            type: 'success'
          });
        } catch (err) {
          this.$message({
            message: "Errore durante l'eliminazione del periodo formativo",
            duration: 5 * 1000,
            center: true,
            showClose: true,
            type: 'error'
          });
        }
      })
      .catch(err => {
        console.info(err);
      });
  }

  async onChange(periodoFormativoId: string) {
    const pf = await periodoFormativoService.Detail(periodoFormativoId);
    const idx = this.list.findIndex(f => f.id === periodoFormativoId);
    if (idx >= 0) {
      this.list.splice(idx, 1, pf);
    } else {
      this.list.push(pf);
    }
    this.showManage = false;
  }

  ricalcolaAdempienzaLoading: boolean = false;
  async onSetClosed(periodoFormativoId: string) {
    await this.onChange(periodoFormativoId);
    const pf = this.list.find(f => f.id === periodoFormativoId);
    if (pf.stato === 'chiuso' && this.$can('CREDITI_GIORNALISTA.ricalcolaAdempienzePeriodoFormativo')) {
      this.$msgbox({
        title: "Ricalcolo dell'adempienza",
        message: `Aggiornare il <i>flag</i> dell'adempienza per tutti i giornalisti iscritti a https://formazionegiornalisti.it al periodo formativo ${pf.dataInizio}-${pf.dataFine} appena chiuso?<br>
                L'operazione di aggiornamento del <i>flag</i> di adempienza è una <strong>operazione non reversibile</strong> che può richiedere diversi minuti.<br>
                Continuare?`,
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        type: "info",
        dangerouslyUseHTMLString: true
      })
        .then(() => {
          this.loading = true
          creditiService.RicalcoloAdempienzePeriodoFormativo(periodoFormativoId).then(() => {
            this.ricalcolaAdempienzaLoading = false;
          }).catch(() => {
            this.ricalcolaAdempienzaLoading = false;
          });
          this.loading = false;
        })
        .catch(() => { });
    }
  }


  async pageChanged(page: number) {
    this.query.page = page - 1;
  }

  private fetchDataDebounced = debounce(async (self: this) => {
    await this.fetchData();
  }, 400);

  async fetchData() {
    try {
      this.loading = true;
      this.pagination.total = await periodoFormativoService.Count(this.query);
      this.list = await periodoFormativoService.List(this.query);
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.$message({
        message: 'Errore durante il recupero dei periodi formativi',
        duration: 5 * 1000,
        showClose: true,
        type: 'error'
      });
    }
  }
}
