import { store } from '@/store';
import { ElForm } from 'element-ui/types/form';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { periodoFormativoService } from '../services/periodoFormativoService';

@Component({})
export default class ChangeStatePeriodoFormativo extends Vue {
  @Prop({ required: true })
  periodoFormativo: periodoFormativo.item;

  periodiFormativi: periodoFormativo.item[] = [];

  command: {
    periodoFormativoId: string,
    periodoFormativoState: 'pubblicato' | 'corrente' | 'chiuso',
    periodoFormativoActionRequiredId: string,
    periodoFormativoActionRequiredState: 'corrente' | 'chiuso',
  } = {
      periodoFormativoId: null,
      periodoFormativoState: null,
      periodoFormativoActionRequiredId: null,
      periodoFormativoActionRequiredState: null,
    }

  async created() {
    this.command.periodoFormativoId = this.periodoFormativo.id;
    const pfs = store.getters.periodiFormativi.list();
    this.periodiFormativi = pfs.filter(f => f.id !== this.periodoFormativo.id);
  }

  get computedPeriodoFormativoNome() {
    return `periodo formativo ${this.periodoFormativo.dataInizio}-${this.periodoFormativo.dataFine}`
  }

  get formRules() {
    return {
      periodoFormativoState: [{ required: true, trigger: "change", message: `E' obbligatorio selezionare lo stato successivo del ${this.computedPeriodoFormativoNome} che si trova in stato ${this.$t(`periodoFormativo.stati.${this.periodoFormativo.stato}`)}` }],
      periodoFormativoActionRequiredId: [{ required: true, trigger: "change", message: `E' obbligatorio selezionare il periodo formativo da impostare a ${this.$t(`periodoFormativo.stati.${this.command.periodoFormativoActionRequiredState}`)}` }],
    }
  }

  get stati(): string[] {
    const statiDisponibili = ['inCompilazione', 'pubblicato', 'corrente', 'chiuso'];
    statiDisponibili.splice(0, statiDisponibili.indexOf(this.periodoFormativo.stato) + 1);
    return statiDisponibili;
  }

  get periodoFormativoActionChange(): { required: boolean, options: periodoFormativo.item[] } {
    switch (this.command.periodoFormativoState) {
      case 'corrente': {
        this.command.periodoFormativoActionRequiredState = 'chiuso';
        return { required: true, options: this.periodiFormativi.filter(f => f.stato === 'corrente') }
      }
      case 'chiuso':
        this.command.periodoFormativoActionRequiredState = 'corrente';
        return { required: true, options: this.periodiFormativi.filter(f => f.stato === 'pubblicato') }
      default:
        this.command.periodoFormativoActionRequiredState = null;
        return { required: false, options: [] }
    }
  }

  saving: boolean = false;
  save() {
    (this.$refs.form as ElForm).validate(valid => {
      if (valid) {
        if (this.periodoFormativoActionChange.required) {
          let toSetCurrent = this.command.periodoFormativoId;
          let toSetClose = this.command.periodoFormativoActionRequiredId;

          if (this.command.periodoFormativoActionRequiredState === 'corrente') {
            toSetCurrent = this.command.periodoFormativoActionRequiredId;
            toSetClose = this.command.periodoFormativoId
          }
          periodoFormativoService.ChangeStatoToCorrente(toSetCurrent, toSetClose)
            .then((result => {
              this.$emit("current", toSetCurrent);
              this.$emit("closed", toSetClose);
              this.onThen(result);
            }))
            .catch(this.onCatch)
        }
        else {
          periodoFormativoService.ChangeStatoToPubblicato(this.command.periodoFormativoId)
            .then((result => {
              this.$emit("published", this.command.periodoFormativoId);
              this.onThen(result);
            }))
            .catch(this.onCatch)
        }
      }
    })
  }

  private onThen(result: periodoFormativo.item | periodoFormativo.item[]) {
    this.$message({
      message: `Lo stato del ${this.computedPeriodoFormativoNome} è stato cambiato correttamente a <i>${this.$t(`periodoFormativo.stati.${this.command.periodoFormativoState}`)}</i>`,
      showClose: true,
      dangerouslyUseHTMLString: true,
      type: 'success',
      duration: 5 * 1000
    })
    this.$emit("close");
  }

  private onCatch(e: any) {
    console.error(e);
    this.$message({
      message: `Si è verificato un errore nel cambio di stato per il ${this.computedPeriodoFormativoNome} a <i>${this.$t(`periodoFormativo.stati.${this.command.periodoFormativoState}`)}</i>.<br>Si prega di compilare nuovamente il form, di controllarne la correttezza e riprovare.`,
      showClose: true,
      dangerouslyUseHTMLString: true,
      type: 'error',
      duration: 5 * 1000
    });
  }
}
