import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { periodoFormativoService } from '../services/periodoFormativoService';
import { ElForm } from 'element-ui/types/form';
import { formatTime } from '@/filters';
import { pofService } from '../services/pianoOffertaFormativaService';

@Component({})
export default class ManagePeriodoFormativo extends Vue {
  title: string = "Inserimento nuovo Periodo Formativo";

  @Prop()
  periodoFormativo: periodoFormativo.item;

  @Prop()
  periodiFormativi: periodoFormativo.item[];

  manage: periodoFormativo.item = null;

  nextInizioPeriodoFormativo: Date = new Date();
  selectedRangeAnni: number[] = [];
  created() {
    if (this.periodoFormativo) {
      this.manage = Object.assign({}, this.periodoFormativo);
    } else {
      this.manage = {
        dataFine: null,
        dataInizio: null,
        id: null,
        nome: null,
        dataFineProroga: null
      } as periodoFormativo.item;
    }

    if (this.periodiFormativi && this.periodiFormativi.length > 0) {
      const annoUtileSuccessivo = (Math.max(...this.periodiFormativi.map(m => m.dataFine)) + 1);
      this.nextInizioPeriodoFormativo = new Date(annoUtileSuccessivo, 0, 1);
      this.periodiFormativi.forEach(f => {
        let inizio: number = f.dataInizio;
        while (inizio <= f.dataFine) {
          this.selectedRangeAnni.push(inizio);
          inizio++;
        }
      })
    }
  }

  get formRules() {
    return {
      nome: [{ required: true, trigger: 'blur', message: `Il campo 'Nome' è obbligatorio` }],
      dataInizio: [{ required: true, trigger: 'blur', message: `Il campo 'Data inizio' è obbligatorio` }],
      dataFine: [{ validator: this.validateDataFine }]
    };
  }

  validateDataFine(rule, value, callback) {
    if (!value || value === '') {
      callback(new Error("Il campo 'Data fine' è obbligatorio"))
    } else if (value <= this.manage.dataInizio) {
      callback(new Error("Il campo 'Data fine' deve essere superiore alla 'Data inizio'"))
    } else {
      callback();
    }
  }

  get dataInizioPickerOptions() {
    const _self = this;
    return {
      disabledDate(time: Date) {
        if (_self.selectedRangeAnni.includes(time.getFullYear())) {
          return true;
        }

        if (!_self.manage.dataFine) return false;
        return time.getFullYear() > _self.manage.dataFine;
      }
    };
  }

  get dataFinePickerOptions() {
    const _self = this;
    return {
      disabledDate(time: Date) {
        if (!_self.manage.dataInizio) return false;
        return time.getFullYear() <= _self.manage.dataInizio;
      }
    };
  }

  get computedTitle() {
    if (!this.manage || (this.manage && (!this.manage.dataInizio || !this.manage.dataFine)))
      return null;
    return `Periodo Formativo ${this.manage.dataInizio}-${this.manage.dataFine}`;
  }

  @Watch('computedTitle')
  dateRangeChange(n, o) {
    if (n && n !== o && (!this.manage.nome || this.manage.nome === o)) {
      this.manage.nome = n;
      (this.$refs.form as ElForm)?.validate();
    }
  }

  saving: boolean = false;
  async add() {
    (this.$refs.form as ElForm)?.validate(async valid => {
      if (valid) {
        this.saving = true;
        if (!this.manage.id) {
          const exists = await periodoFormativoService.Exist({ dataInizio: this.manage.dataInizio, dataFine: this.manage.dataFine } as periodoFormativo.exist);
          if (exists) {
            this.$alert(`Il ${this.computedTitle} si sovrappone ad un periodo formativo esistente`, 'Attenzione!', {
              confirmButtonText: 'Ok',
              type: "warning",
              dangerouslyUseHTMLString: true
            })
            this.saving = false;
            return;
          }
        }

        periodoFormativoService.Set(this.manage).then((periodo) => {
          this.$msgbox({
            title: `Aggiungere i Piani offerta formativa`,
            message: `Il ${this.computedTitle} è stato aggiunto correttamente!<br>Si vuole <strong>aggiungere i Piani offerta formativa</strong> per il nuovo periodo formativo?`,
            confirmButtonText: `Si`,
            cancelButtonText: `No`,
            showCancelButton: true,
            dangerouslyUseHTMLString: true,
            type: 'success'
          }).then(async () => {
            let pfDataInizio = periodo.dataInizio;
            let pfDataFine = periodo.dataFine;
            while (pfDataInizio <= pfDataFine) {
              for (let month = 0; month < 11; month = (month + 2)) {
                const dataInizio = new Date(pfDataInizio, month, 1);
                const dataFine = new Date(pfDataInizio, (month + 1), new Date(pfDataInizio, month + 2, 0).getDate());
                const pof: pianoOffertaFormativa.item = {
                  id: null,
                  periodoFormativoId: periodo.id,
                  nome: `Piano offerta formativa ${formatTime(dataInizio, 'MMMM yyyy')} - ${formatTime(dataFine, 'MMMM yyyy')}`,
                  dataInizio: formatTime(dataInizio, "YYYY-MM-DD"),
                  dataFine: formatTime(dataFine, "YYYY-MM-DD"),
                  attivo: false
                }
                await pofService.Set(pof);
              }
              pfDataInizio++;
            }

            this.saving = false;
            this.$emit('change', periodo.id);
          }).catch(() => {
            this.saving = false;
            this.$emit('change', periodo.id);
          });

        }).catch((err) => {
          this.$message({
            message: `Errore durante la creazione del ${this.computedTitle}`,
            type: 'error'
          })
        })
      }
    })
  }
}
