import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

class CreditiService extends baseRestService {
    constructor() {
        super();
        this.baseUrl = () => settings.baseUrl;
    }

    async Count(params?: crediti.queryParams): Promise<number> {
        return await this.Get(`/api/crediti-giornalista/count`, params);
    }

    async List(params?: crediti.queryParams): Promise<crediti.item[]> {
        return await this.Get("/api/crediti-giornalista", params);
    }

    async GetCreditiAziendali(periodoFormativoId: string, anagraficaGiornalistaIds: string[]): Promise<crediti.creditiAziendali[]> {
        return await this.Post("/api/crediti-giornalista/crediti-da-corsi-aziendali", { periodoFormativoId, anagraficaGiornalistaIds });
    }

    async AddEsenzione(item: crediti.item): Promise<crediti.item> {
        return await this.Post('/api/crediti-giornalista/add-esenzione', item);
    }

    async RicalcoloAdempienzePeriodoFormativo(periodoFormativoId: string): Promise<void> {
        return await this.Put(`/api/crediti-giornalista/ricalcolo-adempienza/${periodoFormativoId}`, null);
    }

    async RicalcoloAdempienza(periodoFormativoId: string, anagraficaGiornalistaId: string): Promise<void> {
        return await this.Put(`/api/crediti-giornalista/ricalcolo-adempienza/${periodoFormativoId}/${anagraficaGiornalistaId}`, null);
    }
}

export const creditiService = new CreditiService();